import React from 'react';
import classnames from 'classnames';

import styles from './index.module.less';

const certificationList = [
  {
    id: 1,
    imgUrl: '/images/certification-1.png',
    text: 'ISO9001',
  },
  {
    id: 2,
    imgUrl: '/images/certification-2.png',
    text: 'ISO14001',
  },
  {
    id: 3,
    imgUrl: '/images/certification-3.png',
    text: 'ISO45001',
  },
  {
    id: 4,
    imgUrl: '/images/certification-4.png',
    text: '软件产品证书',
  },
  {
    id: 5,
    imgUrl: '/images/certification-5.png',
    text: '可信云',
  },
];

const Certification = ({ whiteBg }) => {
  const sty = classnames(styles.certification, {
    [styles.whiteBg]: whiteBg,
  });
  return (
    <div className={sty}>
      <div className={styles.center}>
        <div className={styles.title}>权威机构认证，为企业保驾护航</div>
        <div className={styles.content}>
          {certificationList.map((i) => (
            <div key={i.id} className={styles['cert-item']}>
              <img src={i.imgUrl} alt="" />
              <div>{i.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certification;
